.p-fileupload.p-fileupload-advanced.p-component {
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    transition:
        border 0.2s ease-in;
    &:hover {
        border: 1px solid var(--primary-500) !important;
    }
}

.p-fileupload .p-fileupload-content {
    border: revert;
    border-top: 1px solid #e5e7eb;
}

.visible-description {
    display: flex;
}

.file-upload {
    max-width: calc(100vw - (100vw/6));
    overflow: hidden;
}

.file-upload .p-message-wrapper {
    max-width: 100%;
}

@media screen and (max-width: 768px) {
    .visible-description {
        display: none;
    }
}

@media screen and (max-width: 450px) {
    .visible-tag {
        display: none;
    }
    .file-upload .p-message-wrapper {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .file-upload .p-message-wrapper .p-message-close.p-link {
        margin-left: revert;
    }
    .file-upload .p-message-wrapper .p-icon.p-message-icon {
        display: none;
    }
}
