.medical-record .p-card-body {
    width: 100%;
    word-break: break-word;
}

@media screen and (max-width: 991px) {
    .customized-timeline .p-timeline-event:nth-child(even) {
        flex-direction: row !important;
    }
    .customized-timeline .p-timeline-event:nth-child(even) .p-timeline-event-content {
        text-align: left !important;
    }
    .customized-timeline .p-timeline-event-opposite {
        flex: 0;
    }
    .customized-timeline .p-card {
        margin-top: 1rem;
    }
}

@media screen and (min-width: 431px) and (max-width: 991px) {
    .p-timeline-event .health-data-buttons {
        flex-direction: row-reverse;
    }
}

@media screen and (min-width: 991px) {
    .p-timeline-event:nth-child(odd) .health-data-buttons {
        flex-direction: row-reverse;
    }
    .p-timeline-event:nth-child(even) .doctor-full-name {
        justify-content: flex-end;
    }
}
