.p-dialog-content::-webkit-scrollbar, .custom-textarea::-webkit-scrollbar, .p-overlaypanel::-webkit-scrollbar {
    width: 10px;
}

.p-dialog-content::-webkit-scrollbar-track, .custom-textarea::-webkit-scrollbar-track, .p-overlaypanel::-webkit-scrollbar-track {
    background: var(--surface-100);
    border-radius: 6px;
}

.p-dialog-content::-webkit-scrollbar-thumb, .custom-textarea::-webkit-scrollbar-thumb, .p-overlaypanel::-webkit-scrollbar-thumb  {
    background-color: var(--primary-100);
    border-radius: 6px;
    border: 3px solid transparent;
}

.p-dialog-content::-webkit-scrollbar-thumb:hover, .custom-textarea::-webkit-scrollbar-thumb:hover, .p-overlaypanel::-webkit-scrollbar-thumb:hover {
    background-color: var(--primary-200);
}

.suggested-questions-list {
    list-style: revert;
}


.suggested-questions-list li::marker {
    color: var(--primary-500);
    font-size: 20px;
    font-weight: bold;
}

.dialog-content-width {
    width: 50%;
}

@media screen and (max-width: 500px) {
    .dialog-content-width {
        width: 80%;
    }
}

.dialog-content-width .p-dialog-content {
    padding: 0 1.5rem 0.5rem 1.5rem;
}