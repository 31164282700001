.custom-textarea {
    all: unset;
    width: 100%;
    height: auto;
    outline: none;
    line-height: 1.5;
    padding: 10px;
    border-radius: 25px 0 0 25px;
    resize: none;
    text-align: left;
    word-break: break-word;
}

.custom-textarea::placeholder {
    color: #888;
}

.message-container {
    border-radius: 25px;
    border: 1px solid #d1d5db !important;
    transition:
        border 0.2s ease-in,
        box-shadow 0.2s ease-in;
    &:hover {
        border: 1px solid var(--primary-500) !important;
    }
    &:focus-within {
        border: 1px solid var(--primary-500) !important;
        box-shadow: 0 0 0 0.2rem #ddd6fe !important;
    }
}

.icon-rotate {
    transform: rotate(45deg);
}
