.btn-nav {
    background-color: var(--primary-100);
    border-radius: 6px;
    display: flex;
    line-height: 1;
    padding: 0.5rem;
    box-shadow: 2px 4px 6px var(--primary-500);
    transition:
        scale 0.25s ease-in,
        opacity 0.25s ease-in,
        filter 0.25s ease-in;
    &:hover {
        scale: 1.2;
    }
}

ul:has(.btn-nav:hover) .btn-nav:not(:hover) {
    scale: 0.8;
    opacity: 0.8;
    filter: blur(4px);
}

.nav-button {
    display: flex;
    line-height: 1;
    padding: 0.5rem;
    transition: background-color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
    &:hover {
        background-color: #ebe7fc;
    }
    &:active {
        box-shadow: 2px 4px 6px var(--primary-500);
    }
}
