header {
    display: flex;
    margin: 0 calc(100vw / 12);
    align-items: center;
}

a:link, a:visited {
    text-decoration: none;
    color: var(--primary-500);
    font-weight: 500;
}
