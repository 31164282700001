.tox-tinymce {
    border: 1px solid #d1d5db !important;
    border-radius: 6px !important;
    transition:
        border 0.2s ease-in,
        box-shadow 0.2s ease-in;
    &:hover {
        border: 1px solid var(--primary-500) !important;
    }
}

.tox-edit-focus {
    box-shadow: 0 0 0 0.2rem #ddd6fe !important;
    border: 1px solid var(--primary-500) !important;
}

.tox .tox-edit-area::before {
    border: revert !important;
}

.dialog-custom-header .p-dialog-header {
    padding: 1.5rem 1.5rem 0 1.5rem;
}

.register-category {
    text-decoration: underline;
    cursor: pointer;
    transition:
        text-shadow 0.2s ease-in,
        font-weight 0.2s ease-in;
    &:hover {
        text-shadow: 0 0 32px red;
        font-weight: bold;
    }
}